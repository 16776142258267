class Helpers {

    static objectFormBuilder(form, object) {


        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                if (object[name] === undefined || object[name] === null || object[name] === "null") {

                    form.append(name, '');
                } else {

                    form.append(name, object[name]);
                }
            }
        }

        return form;
    }

    static normalizeJson(object) {
        let newObject = {};
        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                newObject[name] = object[name] ? object[name].toString() : '';
            }
        }

        return newObject;

    }

    getDeviceName() {
        let name = navigator.appCodeName;
        return name.replace(" ", "_").toLowerCase();
    }

    static getIdFromSlug(slug) {
        if (slug) {

            let name = slug.split("-");
            return name[name.length - 1];
        }
        return 0;

    }

    static getTitleFromSlug(slug) {
        if (slug) {

            let name = slug.split("-");
            let length = name.length - 1;
            let title = "";
            for (let i = 0; i < length; i++) {
                title += name[i] + " ";
            }
            return title;
        }
        return "";

    }

    static getMomentDateFromSQL(date, moment) {
        if (date) {
            return moment(new Date(date));
        }
        return '';
    }

    static getSQLDateFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static displayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + " " + month + ", " + year;
        }
        return '';
    }

    static displayDayMonth(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];

            return day + " " + month;
        }
        return '';
    }

    static displayDateTime(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (date) {
            let dateTime = new Date(date);

            return dateTime.getDate() + " " + months[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours() - 1, dateTime.getMinutes(), dateTime.getSeconds()).toLocaleTimeString();

        }
        return '';
    }
    static download(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    static downloadOrOpen(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    static createSlug(title, id) {
        return title.replace(" ", "-") + "-" + id;
    }


    /*------------------------------
   *
   * ------------------------------
   *The authorize me must
   *ensure that it
   *has the application, type
   * and menu
   *
    */

    static authorizeMe(auth, type, menu) {
        try {

            return auth[type].includes(menu);

        } catch (e) {

        }
        return false;

    }


}

export default Helpers;